import { useEffect } from 'react';
import styled from 'styled-components';
import BasicLink from "@common/links/BasicLink";
import Button from "@common/buttons/Button";
import * as Sentry from "@sentry/nextjs";
import Router from 'next/router';

Sentry.init({
    dsn: "https://78d2ee5671b54e3fb52f48b029bac479@o231142.ingest.sentry.io/6148127",
    tracesSampleRate: 1.0,
    debug: false,
});



const notifySentry = (url) => {
        Sentry.configureScope((scope) => {
            scope.setTag(`ssr`, false);
            scope.setExtra(`url`, url);
        })
        Sentry.captureException(new Error(404));
  };


const ErrorLayout = styled.div`
    text-align: center;
    min-height: 60vh;
    padding: 60px 20px;
    & h1 {
        margin: 0px;
        font-size: 4rem;
        color: ${props => props.theme.colors.darkPrimary};
        margin-bottom: 10px;
        font-family: ${props => props.theme.fonts.headline};
        text-transform: uppercase;

    }
    & p {
        color: ${props => props.theme.colors.darkPrimary};
        font-size: 2.4rem;
        max-width: 600px;
        margin: auto;
        line-height: 40px;
        & a {
            color: ${props => props.theme.colors.darkPrimary};
            text-decoration: underline;
        }
        margin-bottom: 10px;
    }
    @media (max-width: ${props => props.theme.breakPoints.sm}) {
        padding: 30px 10px;
        & h1 {
            font-size: 2.6rem;
        }
        & p {
            font-size: 1.78rem;
            line-height: 30px;
        }
    }

`

function Custom404() {

    // On intital load set local state with the contetnful information
    useEffect(() => {
        
        notifySentry(Router.asPath)
    }, [])

    return (
        <ErrorLayout>
            <h1>Oops, we missed a spot.</h1>
            <p>We couldn't find the page you're looking for. If you feel this is an error or you need customer assistance, please contact us  <BasicLink href={"/help"} content="here" />.</p>
            <Button
                link
                href="/"
                modifiers={[
                    "large"
                ]}
            >
                Go Home
            </Button>
        </ErrorLayout>
    )
}

export default Custom404